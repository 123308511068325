.search-by-container {
    padding: 0 0 1rem;
    span {
        font-size: 14px;
    }
    h4 {
        font-size: 0.875rem;
        margin: 0;
    }
    .tab-action-group {
        padding:  1rem 0;
        .action-btn-group {
            display: flex;
            margin-top: 1.5rem;
            button {
                margin-right: 0.5rem;
                font-weight: 400;
                height: 35px;
                line-height: normal;
            }
            .mat-stroked-button{
                border: 2px solid $secondary!important;
            }
            .mat-stroked-button.disabled-btn{
                color: #aaa;
                border: 2px solid #aaa!important;
            }
        }
    }
    .tab-action-group.no-tb-space{
        padding: 0!important;
    }
}
.mat-tab-label.mat-focus-indicator.mat-tab-label-active{
    opacity: 1;
}

.accordion-table-filter {
    padding: 0;
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-device-width: 700px), screen and (max-width: 700px) { 
        flex-direction: column;
        align-items: flex-start; 
        height: auto;
    }
    h4 {
        font-size: 1rem;
        margin: 0;
    }
    button {
        //margin-left: auto;
        padding-right: 0;
        padding-left: 0;
    }
}
// accordian
.accordion-table {
    @media screen and (max-device-width: 480px), screen and (max-width: 480px){
        .mat-table {
            max-width: 380px !important;
            overflow: auto!important;
            display: block!important;
        }
    }
    @media screen and (min-device-width: 481px) and (max-device-width: 920px), 
            screen and (min-device-width: 481px) and (max-width: 920px) {
        .mat-table {
            //max-width: calc(100% - 40px) !important;
            max-width: calc(100% - 10px) !important;
            overflow: auto!important;
            display: block!important;
        }
    }
    thead {
        background-color: rgba($color: #000000, $alpha: 0.15);
        th {
            font-size: 0.875rem;
        }
    }
    .th-acc-expand {
        width: 1.25rem;
    }
    .acc-expand-icon {
        cursor: pointer;
        position: relative;
        z-index: 1;
    }
    .menu {
        @include rotate(270);
    }
    .example-expanded-row {
        .acc-expand-icon {
            @include rotate(180)
        }
    }
    .custom-tab {
        .mat-tab-label {
            min-width: auto;
        }
    }
    .mat-column-expandedDetail {
        background-color: #10273f;
    }
    .example-element-detail {
        width: 100%;
        @media screen and (max-device-width: 640px), screen and (max-width: 640px) {
            .custom-tab {
                .mat-tab-body-content .flex-wrap {
                    flex-direction: column;
                }
            }
        }
    }
}
// Border highlighted
.coverage-expired-active {
    border-left: 4px solid #E22908 !important;
    td {
        border-top: 1px solid #E22908;
        border-left: 0!important;
        border-bottom: 1px solid #E22908;
        border-right: 0 !important;
        &:nth-child(1) {
            border-left: 4px solid #E22908 !important;
        }
        &:-webkit-nth-child(1) {
            border-left: 4px solid #E22908 !important;
        }
    }
}
.coverage-start-active {
    border-left: 4px solid #FF8400 !important;
    td {
        border-top: 1px solid #FF8400;
        border-left: 0!important;
        border-bottom: 1px solid #FF8400;
        border-right: 0 !important;
        &:nth-child(1) {
            border-left: 4px solid #FF8400 !important;
        }
        &:-webkit-nth-child(1) {
            border-left: 4px solid #FF8400 !important;
        }
    }  
}

.coverage-expand-expired-active {
    border-left: 4px solid #E22908 !important;
    td {
        border-top: 1px solid #E22908;
        border-left: 0!important;
        border-bottom: 1px solid #E22908;
        border-right: 0 !important;
        &:nth-child(1) {
            border-left: 4px solid #E22908 !important;
        }
        &:-webkit-nth-child(1) {
            border-left: 4px solid #E22908 !important;
        }
    }
}
.coverage-expand-start-active {
    border-left: 4px solid #FF8400 !important;
    td {
        border-top: 1px solid #FF8400;
        border-left: 0!important;
        border-bottom: 1px solid #FF8400;
        border-right: 0 !important;
        &:nth-child(1) {
            border-left: 4px solid #FF8400 !important;
        }
        &:-webkit-nth-child(1) {
            border-left: 4px solid #FF8400 !important;
        }
    }
}
table.coverage-table tr {
    border-left: 0!important;
    -webkit-border-left: 0!important;
    td.mat-cell {
        border: 0!important;
        -webkit-border: 0!important;
    }
}


.drug-note{
    position: absolute;
    top: 33px;
    left: 0;
}
.accordian-detial-wrapper{
    min-height: calc(100vh - 260px);
}
// Accordion table footer
.accordion-table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.25rem 0 0;
    position: relative;
    @media screen and (max-device-width: 880px), screen and (max-width: 880px) { 
        flex-wrap: wrap;
        justify-content: center!important;
        flex-direction: column;
        .mat-paginator-container {
            justify-content: flex-start;
            padding: 0;
            margin-top: 10px;
            flex-direction: column!important;
        }
    }
    // legends
    .legends {
        display: flex;
        align-items: center;
        .legends-item {
            font-size: 0.75rem;
            color: #BBBBBB;
            font-weight: 400;
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-right: 1.25rem;
            }
            span {
                display: block;
                min-width: 1rem;
                min-height: 1rem;
                @include radius(0.25rem);
                margin-right: 0.35rem;
                &.ce-legend {
                    background-color: #E22908;
                }
                &.cs-legend {
                    background-color: #FFA800;
                }
            }
        }
    }
}

:host::ng-deep .page-wrapper {
  @media screen and (max-device-width: 700px), screen and (max-width: 700px) {
    height: calc(100vh - 165px);
    width: 100% !important;
    overflow-y: scroll!important;
  }
}