*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* Scrollbar Width */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

@mixin component-color($brand-theme) {
  $config: mat-get-color-config($brand-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);
  $border-color: mat-color($primary, 200);

  .mat-app-background, .dashboard-wrapper {
    background: linear-gradient(117.23deg, #002139 35.35%, #0B4060 98.67%);
  }
  
  .mat-table,
  .mat-paginator,
  .mat-select-panel,
  .menu-content {
    background-color: mat-color($primary);
    color: mat-color($primary, "500-contrast");
  }
.footer{
  background-color: mat-color($primary, 700);
}
  .mat-expansion-panel {
    background-color: mat-color($primary, 800);
    color: mat-color($primary, "500-contrast");
  }
  table {
    a {
      color: mat-color($primary, "500-contrast");
    }
  }

  .text-primary {
    color: mat-color($primary);
  }
  .text-accent {
    color: mat-color($accent);
  }
  .bg-primary {
    background-color: mat-color($primary);
    color: mat-color($primary, "500-contrast");
  }
  .bg-accent {
    background-color: mat-color($accent);
    color: mat-color($accent, "500-contrast");
  }
  .text-label,
  .mat-list-base .mat-subheader {
    color: mat-color($primary, 200);
  }

  .mat-checkbox-checkmark {
    fill: mat-color($accent, "500-contrast");
  }
  .mat-checkbox-checkmark-path {
    stroke: mat-color($accent, "500-contrast") !important;
  }

  .mat-dialog-container {
    background-color: mat-color($primary);
    color: mat-color($primary, "500-contrast");
  }

  .dashboard-sidenav {
    background: linear-gradient(136.29deg, #0B4060 36.83%, #114B6E 63.57%);

    color: mat-color($accent, "500-contrast");

    .selected {
      .mat-list-icon {
        color: mat-color($accent);
      }
      .mat-line {
        color: mat-color($accent);
      }
    }

    .submenu {
      mat-nav-list {
        background-color: rgba($color: #000000, $alpha: 0.2);
      }
    }
  }

  .or {
    &:before {
      border-top: 1px dashed mat-color($primary, 200);
    }

    span {
      background: mat-color($primary);
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: mat-color($primary);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: mat-color($primary, 400);

    &:hover {
      background-color: mat-color($primary, 400);
    }
  }

  .static-header {
    .static-title {
      border-left-color: mat-color($accent);
    }
  }
}

.downtime-dialog-panel {
  width: 30vw;
  height: auto;
  text-align: center;
  .primary-btn{
    background: linear-gradient(97.06deg, #2B826C 40.3%, #3ABA98 107.32%);
    border-radius: 30px;
    margin: 0 5px;
    font-weight: 400!important;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    min-width: 80px;
  }
  .mat-dialog-title {
    margin: 10px 0;
    padding-bottom: 3px !important;
    font-weight: 400!important;
  }
  p{
    font-size: 13px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 820px), (max-device-width: 820px) {
    width: auto!important;
  }
}

@include component-color($brand-theme);
